import { trim, once } from 'lodash'
import template from './referral_agreement_modal.html'
import {
  RA_PLACEHOLDER_TEXT,
  RA_BODY_COPY,
  RA_HEADER_SHOVEL_COPY_HTML,
} from 'Vanilla/constants/referral_agreement'
import {
  trackPageView,
  trackAcceptDocument,
  trackWriteSignature,
} from 'ReferralAgreement/track_referral_agreement'

const referralAgreementModal = {
  bindings: {
    resolve: '<',
    close: '&',
  },
  template: template,
  controller: [
    '$http',
    '$scope',
    'referralAgreementModel',
    function ($http, $scope, referralAgreementModel) {
      this.$onInit = () => {
        const { source, onModalSuccessCallback, isLargeProject, headerCopy } =
          this.resolve.inputData
        Object.assign(this, {
          source,
          onModalSuccessCallback,
          isLargeProject,
        })

        this.signatureText = ''
        this.placeholder = RA_PLACEHOLDER_TEXT
        this.headerCopy = headerCopy || RA_HEADER_SHOVEL_COPY_HTML
        this.bodyCopy = RA_BODY_COPY

        trackPageView(this.source, isLargeProject)
        referralAgreementModel.markAgreementSeen()

        const writeSignatureOnce = once(trackWriteSignature)
        $scope.$watch('$ctrl.signatureText', () => {
          if (this.signatureText) {
            writeSignatureOnce(this.source, 2)
          }
        })
      }

      this.buttonClass = () => {
        return this.isDisabled()
          ? 'v3-solid-light-grey-button'
          : 'v3-solid-light-blue-button'
      }

      this.isDisabled = () => {
        return !trim(this.signatureText)
      }

      this.submit = () => {
        if (this.isDisabled()) return

        referralAgreementModel.signAgreement(this.signatureText).then(() => {
          trackAcceptDocument(this.source, this.isLargeProject)
          if (this.onModalSuccessCallback) {
            this.onModalSuccessCallback()
          } else {
            location.reload()
          }
          this.close()
        })
      }
    },
  ],
}

export default referralAgreementModal
