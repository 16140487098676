/**	class to access contractor api	*/
var ImageAPI = function () {
  this.load = function (image_id) {
    return $.when($.get(this.images_api_path() + '/' + image_id))
  }

  this.show_contractor_best_photos = function (contractorIds, numPhotos) {
    return $.when(
      $.ajax({
        url: this.images_api_path() + '/contractor_best_photos',
        dataType: 'json',
        data: {
          contractor_ids: contractorIds,
          num_photos: numPhotos,
        },
      })
    )
  }

  this.load_gallery_images = function (gallery_ids, gallery_owner) {
    var data = new Object()

    if (gallery_owner !== undefined) data.gallery_owner = gallery_owner

    data.gallery_ids = gallery_ids

    return $.when(
      $.ajax({
        url: this.images_api_path() + '/galleries',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(data),
        dataType: 'json',
      })
    )
  }

  this.add_image = function (image) {
    return $.when(
      $.ajax({
        url: this.images_api_path(1),
        type: 'POST',
        data: image,
        dataType: 'json',
      })
    )
  }

  this.delete_image = function (image, data_obj) {
    return $.when(
      $.ajax({
        url: this.images_api_path(1) + '/' + image.id,
        type: 'DELETE',
        data: data_obj,
        dataType: 'json',
      })
    )
  }

  this.search_images = function (url) {
    return $.when(
      $.ajax({
        url: url,
        type: 'GET',
      })
    )
  }
}

ImageAPI.prototype = new BZAPI()
ImageAPI.prototype.constructor = ImageAPI

window.ImageAPI = ImageAPI
