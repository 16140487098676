import { fixedAmountPropertySubscriptionTypes } from 'Vanilla/constants/property_data_subscription'
import DataSubscriptionApi from 'Vanilla/api/data_subscription_api'
import template from './admin_upgrade_dropdown_template.html'

const AdminUpgradeDropdownModule = angular
  .module('adminUpgradeDropdown', [])
  .component('adminUpgradeDropdown', {
    bindings: {
      initialSubscriptionType: '@',
      isActivePlan: '@',
    },
    template,
    controller: [
      'bzHttp',
      function (bzHttp) {
        this.$onInit = () => {
          this.isPlanActive = this.isActivePlan === 'true'
          this.dataSubscriptionApi = new DataSubscriptionApi(bzHttp)
          this.subscriptionTypes = fixedAmountPropertySubscriptionTypes
          this.activeSubscriptionType = this.initialSubscriptionType
        }
        this.onChange = () => {
          this.dataSubscriptionApi
            .changeSubscriptionPlan(this.activeSubscriptionType)
            .then(() => {
              location.reload()
            })
        }
        this.cancelSubscription = () => {
          this.dataSubscriptionApi.endSubscriptionPlan().then(() => {
            location.reload()
          })
        }

        this.activateSubscription = () => {
          this.dataSubscriptionApi.activateSubscription().then(() => {
            location.reload()
          })
        }
      },
    ],
  }).name

export default AdminUpgradeDropdownModule
