const referralAgreementLink = [
  'referralAgreementModalService',
  function (referralAgreementModalService) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        const { source, hasSignedReferralAgreement } = attrs
        element.click(() => {
          referralAgreementModalService.launchModal({
            source,
            hasSignedReferralAgreement: hasSignedReferralAgreement === 'true',
          })
        })
      },
    }
  },
]

export default referralAgreementLink
