const referralAgreementModel = [
  'bzHttp',
  function (bzHttp) {
    return {
      markAgreementSeen(contractorId = null) {
        return bzHttp.post('/api/v3/referral-agreement-seen', { contractorId })
      },
      getShortUrl(contractorId = null) {
        return bzHttp.post('/api/v3/referral-agreement-url', { contractorId })
      },
      signAgreement(name, params = {}) {
        const { contractorId, referralFee } = params
        return bzHttp.post('/api/v3/referral-agreement-add-signature', {
          contractorId,
          name,
          referralFee,
        })
      },
    }
  },
]

export default referralAgreementModel
