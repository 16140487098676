const referralAgreementModalService = [
  'urlConstants',
  'modalScreenService',
  function ({ referralAgreement }, modalScreenService) {
    return {
      launchModal: ({
        source,
        hasSignedReferralAgreement,
        onModalSuccessCallback,
        isLargeProject,
        headerCopy,
        referralFee,
        lead,
      }) => {
        if (lead) {
          if (lead.referralFeeAcceptance && hasSignedReferralAgreement) {
            document.location = `${referralAgreement}?from=${window.location.pathname}&source=${source}`
          } else {
            modalScreenService.initDetachedComponent({
              component: 'referralAgreementModalTest',
              windowClass: 'v3-modal v3-modal-simple medium',
              inputData: {
                source,
                onModalSuccessCallback,
                isLargeProject,
                headerCopy,
                referralFee,
                hasSignedReferralAgreement,
                lead,
              },
            })
          }
        } else if (hasSignedReferralAgreement) {
          document.location = `${referralAgreement}?from=${window.location.pathname}&source=${source}`
        } else {
          modalScreenService.initDetachedComponent({
            component: 'referralAgreementModal',
            windowClass: 'v3-modal v3-modal-simple medium',
            inputData: {
              source,
              onModalSuccessCallback,
              isLargeProject,
              headerCopy,
            },
          })
        }
      },
    }
  },
]

export default referralAgreementModalService
