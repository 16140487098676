/* globals angular, $, UserData, SearchTrack */
angular.module('bzSearchModule').controller('SearchContractorsCtrl', [
  '$scope',
  'locationService',
  'projectPreferencesModel',
  'userLocationModel',
  'searchModel',
  'urlConstants',
  'attributeConversionService',
  function (
    $scope,
    locationService,
    projectPreferencesModel,
    userLocationModel,
    searchModel,
    urlConstants,
    attributeConversionService
  ) {
    $scope.keywords = ''
    $scope.noResultsContractorSearch = false
    $scope.loadingContractorSearch = false
    $scope.noResultsLocationSearch = false
    $scope.loadingLocationSearch = false
    $scope.locationInput = ''
    $scope.locationSlug = ''
    $scope.contractorName = ''
    $scope.locationFieldEngaged = false
    $scope.searchType = 'city'
    $scope.keywordsPlaceholder = 'general contractors'
    $scope.userLocationData = userLocationModel.data
    $scope.prepopulateLocation = true
    $scope.formData = null

    $scope.init = function (data, analytics) {
      if (data) {
        data = attributeConversionService.underscoreToCamelDeepAttributes(data)
        if (data.cityState) {
          $scope.locationInput = data.cityState
          $scope.locationSlug = locationService.to_slug(data.cityState)
        }

        $scope.keywords = data.keywords ? data.keywords : ''

        if (data.keywordsPlaceholder) {
          $scope.keywordsPlaceholder = data.keywordsPlaceholder
        }

        if (data.formData) {
          $scope.formData = data.formData
          $scope.prepopulateLocation = false

          if (data.formData.setLocationByUser && !$scope.locationInput) {
            $scope.setLocationByUser()
          }
        }

        $scope.searchServicesOnly = data.searchServicesOnly || false
      }

      if (analytics) {
        $scope.analytics =
          attributeConversionService.underscoreToCamelDeepAttributes(analytics)
      }

      $('input, textarea').placeholder()

      searchModel.getSearchContractorProjectTypes(true) // get services for typeahead

      $scope.ignoreRootScope = data.ignoreRootScope || false
      if (!$scope.ignoreRootScope) {
        $scope.watchUserLocationData()
      }
    }

    $scope.getLocation = function (query) {
      $scope.noResultsLocationSearch = false
      $scope.loadingLocationSearch = true
      const results = locationService.find_city(query)
      results.then(function () {
        $scope.loadingLocationSearch = false
        $scope.noResultsLocationSearch = !results.length
      })
      return results
    }

    $scope.searchSuggestions = function ($viewValue) {
      $scope.noResultsContractorSearch = false
      $scope.loadingContractorSearch = true
      if ($scope.searchServicesOnly) {
        $scope.currentSearchSuggestions =
          searchModel.getSearchContractorProjectTypesSuggestions($viewValue)
      } else {
        $scope.currentSearchSuggestions = searchModel.getSearchSuggestions(
          $scope.locationSlug,
          $viewValue
        )
      }

      $scope.loadingContractorSearch = false
      $scope.noResultsContractorSearch = !$scope.currentSearchSuggestions.length
      return $scope.currentSearchSuggestions
    }

    $scope.keywordsSelected = function ($item) {
      if (
        $item.itemType === 'contractor' &&
        $item.slug &&
        $scope.locationInput
      ) {
        document.location = urlConstants.contractor.profileURL + $item.slug
      } else if ($item.slug) {
        $scope.keywords = $item.name
        $scope.projectTypeKeywords = $item.slug
      }
      $scope.selectedSlug = $scope.projectTypeKeywords || $scope.keywords

      $scope.noResultsContractorSearch = false
      $scope.loadingContractorSearch = false
    }

    /**    set slug based on typeahead suggestion selected    */
    $scope.locationSelected = function ($item) {
      $scope.locationSlug = locationService.to_slug($item)
      $scope.noResultsLocationSearch = false
      $scope.loadingLocationSearch = false
    }

    $scope.watchUserLocationData = function () {
      $scope.$watchCollection(
        'userLocationData',
        function (newValue, oldValue) {
          if (newValue === oldValue) {
            return
          }

          if (!$scope.locationFieldEngaged && $scope.prepopulateLocation) {
            $scope.setLocationDetails(userLocationModel.data)
          }
        }
      )
    }

    $scope.setLocationByUser = function () {
      var locationDetails = UserData.location()

      if (locationDetails && locationDetails.city && locationDetails.state) {
        $scope.setLocationDetails(locationDetails)
      }
    }

    $scope.setListableSlug = function () {
      var suggestedSlug = null

      if (
        $scope.currentSearchSuggestions &&
        $scope.currentSearchSuggestions.$$state
      ) {
        var suggestions = $scope.currentSearchSuggestions.$$state.value

        // Capture the slug of the first suggestion if the user never clicked on it
        if (
          suggestions &&
          suggestions.length > 0 &&
          suggestions[0].name.toLowerCase().trim() ===
            $scope.keywords.toLowerCase().trim()
        ) {
          suggestedSlug = suggestions[0].slug
        }
      }

      // Refer to suggestedSlug first in case the user clicks on a suggestion, but then changes what was typed
      // console.log($scope.keywords)
      $scope.listableSlug =
        suggestedSlug || $scope.selectedSlug || $scope.slugify($scope.keywords)
    }

    $scope.submit = function ($event) {
      $event.preventDefault()
      $scope.setListableSlug()
      SearchTrack.submit(
        {
          keywords: $scope.listableSlug,
          location: $scope.locationInput,
          form_type: $scope.formData.searchSource,
          analytics: $scope.analytics || {},
        },
        () => {
          const locationValue = angular
            .element($event.target)
            .find('.search-form-location-field')
            .val()
          if ($scope.locationInput) {
            if ($scope.locationSlug) {
              $scope.submitCitySearch($event.target)
            } else {
              locationService
                .find_details($scope.locationInput)
                .then(function (location) {
                  $scope.setLocationDetails(location)

                  angular
                    .element($event.target)
                    .find('.search-form-location-field')
                    .val($scope.locationInput)

                  $scope.locationSlug
                    ? $scope.submitCitySearch($event.target)
                    : $scope.submitKeywordSearch($scope.listableSlug)
                })
            }
          } else if (!locationValue) {
            // ^ Don't allow the form to be submitted if the location field is filled
            // out but the option was not selected. If we do this, the user will lose
            // what they typed in.

            $scope.submitKeywordSearch($scope.listableSlug)
          }
        }
      )
    }

    $scope.submitCitySearch = function ($form) {
      angular.element($form).attr('action', $scope.citySearchUrl())
      $form.submit()
    }

    $scope.citySearchUrl = function () {
      return '/' + $scope.locationSlug + '/' + $scope.listableSlug
    }

    $scope.submitKeywordSearch = function (keywords) {
      document.location = '/search?keywords=' + $scope.listableSlug
    }

    $scope.setLocationDetails = function (locationDetails) {
      if (locationDetails.city.length && locationDetails.state.length) {
        $scope.locationInput =
          locationDetails.city + ', ' + locationDetails.state
      }

      $scope.locationSlug = locationService.to_slug($scope.locationInput)

      $scope.setFormGeolocation(locationDetails)
    }

    $scope.setFormAction = function (url) {
      angular.element($scope.formData.formSelector).attr('action', url)
    }

    $scope.setFormGeolocation = function (locationDetails) {
      $('input[name="search[latitude]"]').val(locationDetails.lat)
      $('input[name="search[longitude]"]').val(locationDetails.lng)
    }

    $scope.location_focused = function () {
      $scope.locationFieldEngaged = true // flag is used to prevent initial auto actions after user interacts
    }

    $scope.slugify = function (str) {
      return str
        .trim()
        .toLowerCase()
        .replace(/&/g, '-and-')
        .replace(/[\s\W-]+/g, '-')
        .replace(/^-+|-+$/g, '')
    }
  },
])
