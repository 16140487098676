export const RA_PLACEHOLDER_TEXT = 'To agree, type your name here'

export const RA_HEADER_SHOVEL_COPY_HTML = `
	Get shovel-ready projects from BuildZoom.<br>
	Only pay us if you get hired
`

export const RA_PROJECT_ACCESS = 'Before you access this project'

export const RA_BODY_COPY = `
	We have the fairest approach in the industry.
	Only pay us if you get hired for a project.
	By signing, you agree to pay BuildZoom a referral fee based on the
	value of the overall project.
`
